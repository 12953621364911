<template>
  <RouterView />
</template>

<script>
import { RouterView } from 'vue-router';

// import ChatGPT from './components/ChatGPT.vue'
import 'bulma/css/bulma.css'

export default {
  name: 'App',
  components: {
    RouterView
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-top: 10px;
}
</style>
