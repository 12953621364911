<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column is-5-tablet is-4-desktop is-3-widescreen">
        <div class="box">
          <div class="field">
            <label for="username" class="label">用户名</label>
            <div class="control">
              <input id="username" type="text" placeholder="myname" class="input" v-model="username" required>
            </div>
          </div>
          <div class="field">
            <label for="password" class="label">密码</label>
            <div class="control">
              <input id="password" type="password" placeholder="*******" class="input" v-model="password" required>
            </div>
          </div>
          <div class="msg-error" id="msg-error" style="display:none">
            <p>用户名、密码错误，请重新输入</p>
          </div>
          <div class="field">
            <button class="button is-success is-fullwidth" :class="{ 'is-loading': isLoading }" @click="login()">
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: 'Login',
  data() {
    return {
      "username": "",
      "password": "",
      "isLoading": false
    }
  },

  methods: {
    login() {
      const url = "https://chat-api.dgcontinent.com/login"
      const data = {
        username: this.username,
        password: this.password
      }
      const headers = {
        "Content-Type": "application/json"
      }
      this.isLoading = true

      axios.post(url, data, headers).then(
        resp => {
          const access_token = resp.data.access_token
          localStorage.setItem("token", access_token)
          this.show_error(false)
          this.isLoading = false
          this.$router.push({ path: "/" })
        },
        error => {
          console.log(error)
          this.show_error(true)
          this.isLoading = false
        }
      )
    },

    show_error(show) {
      var error_field = document.getElementById("msg-error")
      if (show) {
        error_field.style.display = 'block'
      }
      else {
        error_field.style.display = 'none'
      }
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  margin-top: 5%;
}

.msg-error {
  display: none;
  color: red;
  margin-bottom: 5px;
}

button {
  margin-top: 8px;
}
</style>
