<template>
  <div class="main">

    <div class="main-content">
      
      <template v-for="(msg,index) in messages" :key="index">
        <div v-if="index > 0" :class="get_class(msg)">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <h5><strong>{{ msg.role }}</strong></h5>
                <p v-html="format_message(msg.content)"></p>
              </div>
            </div>
          </article>
        </div>
      </template>

      <div class="box" id="box-thinking" style="display: none">
        <a class="button is-loading" style="border: none; margin-top: -8px"></a>
        <span style="padding-left: 5px">ChatGPT努力思考中...</span>
      </div>

    </div>

    <nav class="navbar is-fixed-bottom" role="navigation" aria-label="main navigation">
      <div class="is-fullwidth" style="width:100%">
        <div class="columns is-mobile is-gapless">
          <div class="column is-10">
            <textarea id="txt_question" v-model="txt_question" class="textarea is-primary is-fullwidth" placeholder="e.g. Hello world" rows="1"></textarea>
          </div>
          <div class="column">
            <div class="buttons" style="padding: 2px">
              <button class="button is-info is-fullwidth" @click="submit_question()">Send</button>
            </div>
          </div>
        </div>
      </div>
    </nav>

  </div>
</template>

<script>
import axios from "axios"

export default {
  name: 'ChatGPT',
  data() {
    return {
      txt_question: "",
      messages: [
        {"role": "system", "content": "You are a helpful assistant."}
      ]
    }
  },
  created() {
    var token = localStorage.getItem("token")
    if (token == null) {
      this.$router.push({ path: "/login" })
    }
  },
  methods: {
    get_class(msg) {
      if (msg["role"] == "user") {
        return "box user-box is-primary"
      }
      else {
        return "box ai-box is-primary"
      }
    },

    format_message(text) {
      return text.replace(/\n/g, "<br>");
    },

    follow_up_question() {
      this.messages.push({
        "role": "user",
        "content": this.txt_question
      })
    },

    submit_question() {
      this.follow_up_question()
      this.show_thinking(true)

      const url = "https://chat-api.dgcontinent.com/generate-text"
      const data = {
        "messages": this.messages
      }
      const headers = {
        "headers": {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("token")
        }
      }

      this.txt_question = ""
      axios.post(url, data, headers).then(
        resp => {
          this.show_thinking(false)
          this.messages.push(
            resp.data.choices[0].message
          )
        },
        error => {
          this.show_thinking(false)
          alert("系统错误，请重新登录")
          this.$router.push({ path: "/login" })
          console.log(error)
        }
      )
    },

    show_thinking(show) {
      var thinking_box = document.getElementById("box-thinking")
      if (show) {
        thinking_box.style.display = 'block'
      }
      else {
        thinking_box.style.display = 'none'
      }
    }
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
textarea {
  width: 100%;
}

div.main-content {
  padding-bottom: 65px;
}

div.box {
  width: 94%;
  margin-left: 3%;
}

div.content h5 {
  font-size: 1.05em;
}

div.content p {
  margin-left: 2%;
}

div.ai-box {
  flex: 1;
  background-color: rgba(0, 220, 0, 0.10);
}
div.user-box {
  flex: 1;
  background-color: rgba(220, 220, 220, 0.1);
}

nav {
  padding: 2px;
}
</style>
